
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.aboutSlider()
            this.worksSlider()
            this.singleSlider()
            this.eventSlider()
            this.thumbnailsSlider()
            // this.blogSlider()
            // this.pickupSlider()
            // this.eventSlider()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.aboutSlider()
            this.worksSlider()
            this.singleSlider()
            this.eventSlider()
            this.thumbnailsSlider()
            // this.blogSlider()
            // this.pickupSlider()
            // this.eventSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('.js-hero-slider',{
            loop: true,
            effect :'fade',
            speed: 2000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 5000
            }
        })
    }

    aboutSlider(){
        new Swiper ('.js-about-slider',{
            loop: true,
            effect :'fade',
            speed: 2000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 5000
            }
        })
    }

    worksSlider(){
        new Swiper ('.js-works-slider',{
            slidesPerView: 1.2,
                    spaceBetween:  20,
            breakpoints: {
                576: {
                    slidesPerView: 2.5,
                    spaceBetween:  20,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween:  30,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween:  30,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween:  55,
                },
                
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        })
    }

    singleSlider(){
		const single_thumbnails = document.querySelectorAll('.single-slider-thumbnails a');
        
        const elmSwiper = new Swiper('.p-single__slider',{
            // loop:true,
            spaceBetween:  30,
            pagination: {
                el: '.p-single__slider .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                
                576: {
                    spaceBetween:  40,
                },
                768: {
                    spaceBetween:  60,
                }
            },
            on: {
                init :()=> {
                    if(single_thumbnails.length > 0){
                        single_thumbnails[0].classList.add('active')
                    }
                },
                slideChange:()=> {
                    if(single_thumbnails.length > 0){
                        const active_button = document.querySelector('.single-slider-thumbnails a.active');
                        if(active_button){
                            active_button.classList.remove('active')
                        }
                        single_thumbnails[elmSwiper.activeIndex].classList.add('active')
                    }
                },
            },
            simulateTouch: true,

        })
        single_thumbnails.forEach((el,i) => {
            el.addEventListener('click',(e)=>{
                e.preventDefault();
                elmSwiper.slideTo(i);
            })
        })
          
    }

    thumbnailsSlider() {
        new Swiper ('.js-thumbnails__slider',{
            slidesPerView: 4.5,
            spaceBetween:  10,
            breakpoints: {
                576: {
                    slidesPerView: 5.5,
                },
                768: {
                    slidesPerView: 6.5,
                },
                992: {
                    slidesPerView: 9.5,
                }
            }
        })
    }

    eventSlider(){
        new Swiper ('.js-event-slider',{
            slidesPerView: 1.2,
            spaceBetween:  20,
            breakpoints: {
                576: {
                    slidesPerView: 2.5,
                    spaceBetween:  20,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween:  30,
                    allowTouchMove: true
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween:  44,
                    allowTouchMove: false,
                }
            }
        })

        // if (window.matchMedia("(min-width: 991px)").matches) {
        //     blogSlider.destroy(false);
        //     var slides = document.querySelectorAll(".swiper-slide");
        //     for (var i = 0; i < slides.length; i++) {
        //       slides[i].style.width = "calc(33.33% - 20px)";
        //       slides[i].style.marginRight = "20px";
        //     }
        // }
    }

    
    
    blogSlider(){
        // const blog_thumbnails = document.querySelectorAll('.blog-slider-thumbnails a');
        // const blogSlider = new Swiper('.js-blog__slider', {
        //     loop: true,
        //     speed:400,
        //     easing:"linear",
        //     loopAdditionalSlides : 5,
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     simulateTouch:true,
        //     centeredSlides: false,
        //     breakpoints: {
                
        //         576: {
        //             slidesPerView: 'auto',
        //             spaceBetween:  40,
        //         }
        //     }
        // });
        
        // const blogThumbnailAddEvent = (el,i) => {
        //     el.addEventListener('click',function(e){
        //         e.preventDefault();
        //         blogSlider.slideTo(i+1);
        //     })
        // }

        // for(var i = 0; i <  blog_thumbnails.length; i++){
        //     blogThumbnailAddEvent( blog_thumbnails[i],i);
        // }
    }
    pickupSlider(){
        // const pickup = new Swiper('.js-pickup--slider', {
        //     loop: true,
        //     speed:400,
        //     easing:"linear",
        //     loopAdditionalSlides : 5,
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     simulateTouch:true,
        //     centeredSlides: false,
        //     breakpoints: {
                
        //         576: {
        //             slidesPerView: 'auto',
        //             spaceBetween:  40,
        //         }
        //     }
        // })
    }
   
}