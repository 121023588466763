import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.bgColorChange()
            this.bgColorChangeFeatures()
        })
        barba.hooks.leave((data) => {
            window.removeEventListener("resize",this.resize)
        })
        barba.hooks.after((data) => {
            this.bgColorChange()
            this.bgColorChangeFeatures()
        });
    }

    bgColorChange (){
        const bgcolor_change_target = document.querySelectorAll("[data-bgchange-target]");
        if(bgcolor_change_target.length > 0) {
            bgcolor_change_target.forEach( target => {
                const start = target.querySelectorAll("[data-bgchange-start]");
                start.forEach( st => {
                    const color = st.getAttribute("data-bgchange-start");
                    const tl = gsap.to(target, {
                        background: color,
                        scrollTrigger: {
                            trigger: st,
                            start: "top center",
                            toggleActions:'play none none reverse',
                            // markers: true
                        }
                    });
                    tl.scrollTrigger.refresh();
                })
            })
        }
    }

    bgColorChangeFeatures() {
        const featurespage = document.querySelector("#p-features");
        if(featurespage) {
            const bgcolor_change_features_target = document.querySelectorAll("[data-bgchange-features-target]");
            const scroll_wrapper = document.querySelector('.js-sidescroll-content');
            const features_color = document.querySelector("[data-bgchange-features-color]");
            const color = features_color.getAttribute("data-bgchange-features-color");

            const scroll_list = document.querySelector('.js-sidescroll-list');
            this.feturesIstance = gsap.to(bgcolor_change_features_target, {
                background: color,
                scrollTrigger: {
                    trigger: ".l-pagelink",
                    start: () =>{
                        return `${scroll_list.clientWidth - scroll_wrapper.clientWidth} center`
                    },
                    // start : "top top",
                    toggleActions:'play none none reverse',
                    invalidateOnRefresh: true,
                }
            });
        }
        window.addEventListener("resize",this.resize)
    }

    resize(){
        if( this.feturesIstance) {
            this.feturesIstance.scrollTrigger.refresh()
        }
    }
}