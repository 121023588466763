import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.parallaxAnm()
        })
        barba.hooks.after((data) => {
            this.parallaxAnm()
        });
    }
    parallaxAnm (){
        const parallax_anm_item = document.querySelectorAll("[data-parallax-item]");
        if(parallax_anm_item.length > 0) {
            parallax_anm_item.forEach( item => {
                const parallax_anm_target = item.querySelectorAll("[data-parallax-target]");
                const y = item.getAttribute("data-parallax-item");
                gsap.set(parallax_anm_target, { yPercent: 0,});
                gsap.to(parallax_anm_target, {
                    yPercent: y,
                    scrollTrigger: {
                        trigger: parallax_anm_target,
                        start: "top bottom",
                        scrub: 1.5,
                        // markers: true
                    }
                });
            })
        }
    }
}