import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.historyColorChange()
        })
        barba.hooks.after((data) => {
            this.historyColorChange()
        });
    }
    historyColorChange () {
        const historyItems = document.querySelectorAll(".company-history__item");
        if(historyItems.length > 0) {
            historyItems.forEach(item => {
                const point = item.querySelector(".point");
                gsap.to(point, {
                    background: "#FFB800",
                    scrollTrigger: {
                        trigger: item,
                        start: "top center",
                        toggleActions:'play none none reverse',
                        // markers: true
                    }
                });
            });
        }
    }
}