import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

    constructor(){
       
        barba.hooks.afterOnce((data) => {
            this.init(document)
        })
        barba.hooks.leave((data) => {
            window.removeEventListener("resize",this.resize)
        })
        barba.hooks.after((data) => {
            this.init(data.next.container)
        });
    }
    init(d){
        const scroll_wrapper = d.querySelector('.js-sidescroll-content');       
        const scroll_list = d.querySelector('.js-sidescroll-list');

        if(scroll_wrapper) {
            this.distance = () => {
                return (scroll_list.clientWidth - window.innerWidth)
            }
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    // ①スクロール要素を囲う要素の幅（スクロールが見える画面幅）
                    if(scroll_wrapper) {

                        // ②スクロールされる要素
                        scroll_list.style.height = this.distance() + 'px'
                        this.gsapInstance = gsap.to(scroll_list, {
                            // x方向にどのくらい動かすか（左に動かしたいのでマイナス値）
                            // ②スクロールされる要素の幅合計から①画面幅を引いたぶんを動かす
                            x: () => {
                                return ((scroll_list.clientWidth - window.innerWidth)) * -1
                            },
                            ease: 'none',
                            scrollTrigger: {
                                trigger: scroll_wrapper,
                                start: 'top top',
                                // // どこまで動かすか
                                end: () => `+=${scroll_list.clientWidth - scroll_wrapper.clientWidth}`,
                                scrub: true,
                                pin: scroll_wrapper,
                                anticipatePin: 1,
                                invalidateOnRefresh: true,
                                // markers: true
                            },
                        });
                        setTimeout( () => {

                            if(location.hash) {
                                const section = scroll_wrapper.querySelector('[data-scroll-to="' + location.hash.replace("#","") + '"]');
                                if(section){
                                    window.scrollTo( 0, this.gsapInstance.scrollTrigger.start + section.offsetLeft )
                                    // console.log(section.offsetLeft);
                                }
                            }
                        },10)
                    }
                },
                "(max-width: 991.98px)": () => {
                    // ①スクロール要素を囲う要素の幅（スクロールが見える画面幅）
                    if(scroll_wrapper) {

                        setTimeout( () => {

                            if(location.hash) {
                                const section = scroll_wrapper.querySelector('[data-scroll-to="' + location.hash.replace("#","") + '"]');
                                if(section){
                                    window.scrollTo( 0, section.offsetTop )
                                    // console.log(section.offsetLeft);
                                }
                            }
                        },10)
                    }
                },
                
            });
            
            window.addEventListener("resize",this.resize)
        }

    }

    resize(){
        const scroll_wrapper = document.querySelector('.js-sidescroll-content');
        if(scroll_wrapper) {
            const scroll_list = document.querySelector('.js-sidescroll-list');
            if(window.innerWidth > 991.98){
                scroll_list.style.height = (scroll_list.clientWidth - window.innerWidth) + 'px'
            }else{
                scroll_list.style.height = "auto"
            }
            // this.gsapInstance.scrollTrigger.refresh()
            ScrollTrigger.refresh()
        }
    }
}


