import barba from '@barba/core';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init() {
        const asideContact = document.querySelector("#l-footer")
        const contactbtn = document.querySelector("#l-floating")
        if (contactbtn) {
            const options = {
                root: null,
                rootMargin: "0px 0px 0px 0px",
                threshold: 0
            };

            const callback = (entries) => {
                entries.forEach(entry => {
                    if(entry.isIntersecting){
                        contactbtn.classList.add("-bottom")
                    } else {
                        contactbtn.classList.remove("-bottom")
                    }
                })
            }
              
            const observer = new IntersectionObserver(callback, options);
            observer.observe(asideContact)
        }
    }
}