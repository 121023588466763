import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.removeCircle()
        })
        barba.hooks.after((data) => {
            this.removeCircle()
        });
        
    }
    removeCircle () {
        const circle = document.querySelector(".about-intro__circle");
        if(circle) {
            gsap.to(circle, {
                opacity: 0,
                scrollTrigger: {
                    trigger: ".about-intro",
                    start: "bottom bottom",
                    toggleActions:'play none none reverse',
                    // markers: true
                }
            });
        }
    }
}