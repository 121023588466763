import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.featuresWindow()
            this.flowAddClass()
        })
        barba.hooks.after((data) => {
            this.featuresWindow()
            this.flowAddClass()
        });
    }
    featuresWindow (){
        const features = document.querySelector(".index-features");
        if(features) {
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": function() {
                    const windowWrap = document.querySelector("[data-window-wrap]");
                    const windowGrid = document.querySelector("[data-window-grid]");
                    const windowMainImg = document.querySelector("[data-window-main]");
    
                    const windowSide = document.querySelectorAll("[data-window-side]");
                    const windowSideImg = document.querySelectorAll("[data-window-side-img]");
    
                    // グリッドにしている要素全体を固定する
                    ScrollTrigger.create({
                        trigger: windowGrid,
                        pin: windowGrid,
                        start: "center center",
                        endTrigger: features,
                        end: "bottom bottom",
                        // markers: true,
                    })
    
                    // グリッド中央の画像を横に拡大
                    gsap.set(windowMainImg, { width: "30%", height:"500px" });
                    gsap.to(windowMainImg,{
                        width: "100%",
                        height: "100vh",
                        scrollTrigger: {
                            trigger: windowMainImg,
                            start: "top top",
                            endTrigger: windowWrap,
                            end: 'bottom bottom', 
                            scrub: 1.5,
                            // markers: true,
                        }
                    });
    
                    // グリッド左右の要素を拡大
                    gsap.to(windowSide,{
                        scale: 3.5,
                        scrollTrigger: {
                            trigger: windowSide,
                            start: "top top",
                            endTrigger: windowWrap,
                            end: 'bottom bottom', 
                            scrub: 1.8,
                            // markers: true,
                        }
                    });
    
                    // グリッド左右の各画像を拡大
                    gsap.to(windowSideImg,{
                        scale: 1.5,
                        scrollTrigger: {
                            trigger: windowSideImg,
                            start: "top top",
                            endTrigger: windowWrap,
                            end: 'bottom bottom', 
                            scrub: 1.8,
                            // markers: true,
                        }
                    });
    
    
    
                    const featuresSekkei = document.querySelector("[data-features-sekkei]");
                    const featuresSekkeiImg = document.querySelector("[data-features-sekkei-img]");
    
                    if(featuresSekkei) {
                        gsap.fromTo(featuresSekkeiImg,{
                            yPercent : "-52"
                            },{
                            yPercent : "42",
                            scrollTrigger: {
                                trigger: featuresSekkei,
                                start : () => {
                                    return 'top-=' + window.innerHeight + 'px top'; 
                                },
                                end: () => {
                                    return 'bottom+=' + window.innerHeight + 'px top'; 
                                },
                                scrub:true,
                            }
                        });
                    }
    
                    const featuresCyumon = document.querySelector("[data-features-cyumon]");
                    const featuresCyumonImg = document.querySelector("[data-features-cyumon-img]");
    
                    if(featuresCyumon) {
                        gsap.fromTo(featuresCyumonImg,{
                            yPercent : "-52"
                            },{
                            yPercent : "42",
                            scrollTrigger: {
                                trigger: featuresCyumon,
                                start : () => {
                                    return 'top-=' + window.innerHeight + 'px top'; 
                                },
                                end: () => {
                                    return 'bottom+=' + window.innerHeight + 'px top'; 
                                },
                                scrub:true,
                            }
                        });
                    }
                }
            });
            ScrollTrigger.refresh()
        }
    }

    flowAddClass() {
        const flow = document.querySelector(".index-flow");
        if(flow) {
            const lineanm = document.querySelector("[data-line-anm]");
            ScrollTrigger.create({
                trigger: flow,
                start: "top center",
                once: true,
                // markers: true,
                toggleClass: {
                    targets: lineanm,
                    className: "js-scroll-to-lineanm"
                },
            })
        }
    }
}